import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import {
  Box,
  Container,
  createStyles,
  Divider,
  Flex,
  Group,
  Paper,
  Text,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import { IconMail, IconPhone } from "@tabler/icons-react";
import { Helmet } from "react-helmet";

const useStyles = createStyles((theme) => ({
  sectionTitle: {
    fontSize: "3rem",
    //   fontVariant: "small-caps",
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: "#b28a4f",
      transition: "width 0.3s ease-in-out",
      width: 100,
      height: 2,
      marginBottom: theme.spacing.xs,
    },
    ".mantine-Paper-root:hover &::after": {
      width: "100%",
    },
  },
}));

// eslint-disable-next-line
export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  employeesGroups,
}) => {
  const PageContent = contentComponent || Content;
  const { classes } = useStyles();

  return (
    <Box mt={56}>
      <Container size="lg" py="xl">
        <Title
          className={classes.sectionTitle}
          order={1}
          color="rgba(50, 59, 60, 0.8)"
          ff="Oswald"
          ml="sm"
        >
          {title}
        </Title>
        <TypographyStylesProvider>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </TypographyStylesProvider>
        <Divider my="xl" />
        {(employeesGroups ?? []).map((group, i) => (
          <Box key={"employee_group" + i}  mt="xl" mb="xl">
            <Title
              order={2}
              size={40}
              color="rgba(50, 59, 60, 0.8)"
              ff="Oswald"
              ml="sm"
            >
              {group.name}
            </Title>
            <Flex wrap="wrap" justifyContent="space-between">
              {(group.employees ?? []).map((employee, ind) => (
                <Paper
                  key={employee.name + ind}
                  shadow="sm"
                  w={"calc(50% - 50px)"}
                  sx={theme => ({
                    [theme.fn.smallerThan("sm")]: {
                      width: "100%",
                    },
                    [theme.fn.largerThan("md")]: {
                      width: "calc(33% - 16px)",
                    },
                  })}
                  mb="xl"
                  m={"md"}
                >
                  
                  <Box
                    component="img"
                    src={employee.image}
                    alt={employee.name}
                    width="100%"
                    height={200}
                    objectFit="cover"
                    borderRadius={4}
                    mb="sm"
                  />
                  <Box p="md">

                    <Title
                      order={3}
                      color="rgba(50, 59, 60, 0.8)"
                      ff="Oswald"
                    >
                      {employee.name}
                    </Title>
                    <Text
                      order={4}
                      color="rgba(50, 59, 60, 0.4)"
                      mb="xs"
                    >
                      {employee.position}
                    </Text>
                    <Divider mb="sm" />
                    <Group align="center" position="left">
                      <IconPhone size={20} />
                    <Text
                      order={4}
                      color="rgba(50, 59, 60, 0.8)"
                    >
                      {employee.phone}
                    </Text>
                    </Group>
                    <Group align="center" position="left">
                      <IconMail size={20} />
                    <Text
                      order={4}
                      color="rgba(50, 59, 60, 0.8)"
                    >
                      {employee.email}
                    </Text>
                    </Group>
                  </Box>
                </Paper>
              ))}
            </Flex>
            <Divider my="xl" mr="auto" ml={"md"} w="50%"/>
          </Box>
        ))}
        {/* <PageContent className="content" content={content} /> */}
      </Container>
    </Box>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  employeesGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      employees: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          position: PropTypes.string,
          phone: PropTypes.string,
          email: PropTypes.string,
          image: PropTypes.string,
        })
      ),
    })
  ),
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Helmet titleTemplate="%s | Über Uns" />
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        employeesGroups={post.frontmatter.employeesGroups}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        employeesGroups {
          name
          employees {
            name
            position
            phone
            email
            image
        }
      }
    }
  }
}
`;
